<template>
  <div class="all">
    <div class="news">
      <news-list-item v-for="item in list" :data="item" />
      <Spin v-show="loading" fix>
        <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
        <div>加载中...</div>
      </Spin>
    </div>
    <Page
      class="page"
      @on-change="pageChange"
      :total="total"
      :current="page"
      :page-size="9"
      show-elevator
    />
  </div>
</template>

<script>
import NewsListItem from './NewsListItem'

export default {
  name: 'NewsList',
  props: {
    list: Array,
    total: Number,
    page: Number,
    loading: Boolean,
  },
  data() {
    return {}
  },
  methods: {
    pageChange(e) {
      this.$emit('pageChange', e)
    },
  },
  components: {
    NewsListItem,
  },
}
</script>

<style scoped>
.all {
  width: 800px;
}
.news {
  position: relative;
}
.page {
  margin: 25px 0;
  text-align: right;
}
</style>
