<template>
  <div class="listAll">
    <filter-screen v-if="isReady" @filterClk="filterClk" />
    <div class="content w1400">
      <div>
        <news-list
          v-if="isReady"
          :list="newsList"
          :total="total"
          :page="page"
          :loading="loading"
          @pageChange="pageChange"
        />
      </div>
      <div class="mrTop20">
        <q-rcode />
        <scroll-box
          v-if="isReady"
          :option="transOpt1"
          @onClick="rollClk"
          class="mrTop20"
        />
        <scroll-box
          v-if="isReady"
          :option="transOpt2"
          @onClick="rollClk"
          class="mrTop20"
        />
      </div>
    </div>
    <Spin v-if="!isReady" fix>
      <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
      <div>加载中...</div>
    </Spin>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  getAuctionNewsAll,
  getAuctionNewsMenu,
  getAuctionNewsMenuSort,
} from 'network/IndustryInfo'

import FilterScreen from './compontents/listLayout/FilterScreen'
import NewsList from './compontents/listLayout/NewsList'

export default {
  name: 'IndustryList',
  data() {
    return {
      isReady: false,
      newsList: [],
      total: 0,
      page: 1,
      loading: false,
      transOpt1: {
        title: '最新发布',
        list: [],
        isHover: true,
        context: (str) => {
          if (str.title.length >= 10) {
            str.title = str.title.substr(0, 10) + '...'
          }
          return `[${str.marketName}] ${str.title}`
        },
      },
      transOpt2: {
        title: '最热发布',
        list: [],
        isHover: true,
        context: (str) => {
          if (str.title.length >= 10) {
            str.title = str.title.substr(0, 10) + '...'
          }
          return `[${str.marketName}] ${str.title}`
        },
      },
    }
  },
  created() {
    this.apiRes()
  },
  methods: {
    ...mapMutations('industry', [
      'setCate',
      'setType',
      'setCateList',
      'setTypeList',
    ]),
    apiRes() {
      let apis = [
        this.getAuctionNewsAll(),
        this.getAuctionNewsAll(1), // 获取最新
        this.getAuctionNewsAll(0), // 随机获取
        this.getAuctionNewsMenu(),
        this.getAuctionNewsMenuSort(),
      ]
      Promise.all(apis).then((res) => (this.isReady = true))
    },
    filterClk(e) {
      if (e == 1) {
        this.getAuctionNewsMenuSort()
      }
      this.page = 1
      this.getAuctionNewsAll()
    },
    pageChange(e) {
      this.page = e
      this.getAuctionNewsAll()
    },
    rollClk(item) {
      this.$router.push({
        name: 'IndustryDetail',
        query: { id: item.id, page: item.page, subjectId: item.subjectId },
      })
    },
    /* api相关 */
    getAuctionNewsAll(num) {
      return new Promise((resolve) => {
        let data = {
          num: this.page,
          size: 9,
          subjectId: this.filters.cate,
          marketId: this.filters.type == 0 ? null : this.filters.type,
        }
        if (num == 1) {
          data.num = 1
          data.size = 20
          data.subjectId = null
          data.marketId = null
        } else if (num == 0) {
          data.num = parseInt(Math.random() * 20) + 1
          data.size = 20
          data.subjectId = null
          data.marketId = null
        }
        this.loading = true
        getAuctionNewsAll(data).then((res) => {
          this.loading = false
          if (res.code == 0) {
            if (num == 1) {
              this.transOpt1.list = res.data
            } else if (num == 0) {
              this.transOpt2.list = res.data
            } else {
              this.newsList = res.data
              this.total = res.count
            }
            resolve()
          }
        })
      })
    },
    getAuctionNewsMenu() {
      return new Promise((resolve) => {
        getAuctionNewsMenu().then((res) => {
          if (res.code == 0) {
            let list = res.data.map((e) => {
              return {
                id: e.subjectId,
                name: e.groupName,
              }
            })
            this.setCateList(list)
            resolve()
          }
        })
      })
    },
    getAuctionNewsMenuSort() {
      return new Promise((resolve) => {
        getAuctionNewsMenuSort({ subjectId: this.filters.cate }).then((res) => {
          if (res.code == 0) {
            let list = res.data.map((e) => {
              return {
                id: e.marketId,
                name: e.marketName,
              }
            })
            this.setTypeList(list)
            resolve()
          }
        })
      })
    },
  },
  computed: {
    ...mapState('industry', ['filters']),
  },
  components: {
    FilterScreen,
    NewsList,
  },
}
</script>

<style scoped>
.listAll {
  position: relative;
}
.mrTop20 {
  margin-top: 20px;
}
.content {
  display: flex;
  justify-content: space-between;
}
</style>
