<template>
  <div @click="divClk" class="itemAll">
    <h1>{{ data.title }}</h1>
    <div class="types">
      <span>{{ data.marketName }}</span>
      <span>发布时间：{{ fdate }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsListItem',
  props: {
    data: Object,
  },
  methods: {
    divClk() {
      this.$router.push({
        name: 'IndustryDetail',
        query: {
          id: this.data.id,
          page: this.data.page,
          subjectId: this.data.subjectId,
        },
      })
    },
  },
  computed: {
    fdate() {
      return this.$handle.dateFormat(this.data.create, 'y/m/d h:f')
    },
  },
}
</script>

<style scoped>
.itemAll {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 800px;
  height: 130px;
  border-bottom: 1px dashed #e6ebf5;
  cursor: pointer;
}

h1 {
  margin-bottom: 20px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}

.types {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.types span:first-child {
  display: inline-block;
  width: 70px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #25449a;
  background: #dcedff;
  border-radius: 4px;
}

.types span:last-child {
  font-size: 14px;
  color: #4d4d4d;
}
</style>
