<template>
  <div class="all w1400 mrTop20">
    <filter-item
      title="类目"
      @onClick="cateClk"
      :id="filters.cate"
      :list="filters.cateList"
      :hasAll="false"
    />
    <filter-item
      title="类型"
      @onClick="typeClk"
      :id="filters.type"
      :list="filters.typeList"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'FilterScreen',
  data() {
    return {}
  },
  methods: {
    ...mapMutations('industry', ['setCate', 'setType']),
    cateClk(e) {
      if (e.id != this.filters.cate) {
        this.setType(0)
        this.setCate(e.id)
      }
      this.$emit('filterClk', 1)
    },
    typeClk(e) {
      this.setType(e.id)
      this.$emit('filterClk', 2)
    },
  },
  computed: {
    ...mapState('industry', ['filters']),
  },
}
</script>

<style scoped>
.mrTop20 {
  margin-top: 20px;
}
.all {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #e6ebf5;
}
</style>
